import { ChainId, Token } from '@pancakeswap/sdk'
import DEFAULT_TOKEN_LIST from '../config/constants/tokenLists/pancake-default.tokenlist.json'

const mapping = {
  [ChainId.BSC]: 'smartchain',
  [ChainId.ETHEREUM]: 'ethereum',
}

const getTokenLogoURL = (token?: Token) => {
  if (token && mapping[token.chainId]) {
    return `https://assets-cdn.trustwallet.com/blockchains/${mapping[token.chainId]}/assets/${token.address}/logo.png`
  }
  return (
    DEFAULT_TOKEN_LIST.tokens.find((t) => t.chainId === token.chainId && t.address === token.address)?.logoURI || null
  )
}

export default getTokenLogoURL
